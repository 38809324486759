@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
body,html{
  width: 100%;
  overflow-x: hidden;
  margin:0;
  padding:0;
  user-select: none;
}
.Home{
  width: 100%;
}
.logo img{
  height:70px;
  width: 220px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}
.nav-title{
  display: none;
}
.toggle{
  top:20px;
  right:20px;
  width:40px;
  height:40px;
  background:#14df17;
  border: none;
  cursor:pointer;
  display:none;
  justify-content:center;
  align-items:center;
box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
-webkit-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
-moz-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
}
.toggle::before{
  content:'';
  position:absolute;
  width:20px;
  height:2px;
  background: #000;
  transform:translateY(-5px);
  transition:0.2s;
  transition:all 1s;
  -webkit-transform:translateY(-5px);
  -moz-transform:translateY(-5px);
  -ms-transform:translateY(-5px);
  -o-transform:translateY(-5px);
}
.toggle::after{
  content:'';
  position:absolute;
  width:20px;
  height:2px;
  background:#000;
  transform:translateY(5px);
  transition:0.2s;
  transition:all 1s;
}
.toggle.active::before{
  transform:translateY(0) rotate(45deg);
  transition:all 1s;
  -webkit-transform:translateY(0) rotate(45deg);
  -moz-transform:translateY(0) rotate(45deg);
  -ms-transform:translateY(0) rotate(45deg);
  -o-transform:translateY(0) rotate(45deg);
}
.toggle.active::after{
  transform:translateY(0) rotate(-45deg);
  transition:all 1s;
}
.navbar-ul-mobile ul{
  list-style-type: none;
}
.navbar-ul-mobile ul li{
  padding-bottom: 5px;
}
.navbar-ul-mobile ul li div{
  display: flex;
}
.nav-mobile-icon {
  margin:0 5px 0 0;
}
.navbar-ul-mobile ul li a{
  text-decoration: none;
  color: #000;
}
.nav-section{
  position: fixed;
  z-index: 7;
  background: #fff;
  width: 100%;
}
.nav{
  padding: 20px;
  margin: 0 0 0 20px;
  font-size:18px;
}
.navbar-ul{
  margin: -80px 0 0 580px;
}
.navbar-ul ul{
 list-style-type: none;
 display:flex;
}
.navbar-ul ul li{
  padding-left: 20px;
}
.navbar-ul ul li a{
  text-decoration: none;
  color:rgb(24, 22, 22);
  font-weight: 550;
  font-family: "Nunito Sans", sans-serif;
  transition: linear 0.5s;
  -webkit-transition: linear 0.5s;
  -moz-transition: linear 0.5s;
  -ms-transition: linear 0.5s;
  -o-transition: linear 0.5s;
}
.navbar-ul ul li a::before{
  display: none;
  content:"";
  width:20px;
  height:20px;
  background:#14df17;
  position:absolute;
  margin:-10px 0 0 -60px;
  border-radius:20px;
  box-shadow: 11px 10px 19px -7px #14df17;
  -webkit-box-shadow: 11px 10px 19px -7px #14df17;
  -moz-box-shadow: 11px 10px 19px -7px #14df17;
  transition: linear 2s;
}
.navbar-ul ul li a:hover,.navbar-ul ul li:hover{
  font-size:18px;
  font-weight:700;
  color: #14df17;
  transition:linear 0.5s;
  -webkit-transition:linear 0.5s;
  -moz-transition:linear 0.5s;
  -ms-transition:linear 0.5s;
  -o-transition:linear 0.5s;
}
.navbar-ul ul li{
  color:rgb(24, 22, 22);
  font-weight: 600;
  font-family: "Nunito Sans", sans-serif;
  cursor:pointer;
  user-select: none;
}
.navbar-ul ul li div{
  display: flex;
}
.navbar-ul ul li::before{
  display: none;
  content:"";
  width:20px;
  height:20px;
  background:#14df17;
  position:absolute;
  margin:-10px 0 0 -20px;
  border-radius:20px;
  box-shadow: 11px 10px 19px -7px #14df17;
  -webkit-box-shadow: 11px 10px 19px -7px #14df17;
  -moz-box-shadow: 11px 10px 19px -7px #14df17;
  transition: linear 2s;
}
.navbar-ul ul li a:hover::before,.navbar-ul ul li:hover::before{
  display: flex;
  content: "";
  background: #14df17;
  margin:15px 0 0 30px;
  width:25px;
  height: 25px;
  transition: linear 2s;
}
.nav-icon{
  margin:5px 0 0 5px;
  font-size: 16px;
  transition: 1s linear;
  -webkit-transition: 1s linear;
  -moz-transition: 1s linear;
  -ms-transition: 1s linear;
  -o-transition: 1s linear;
}
.active{
  transform: rotateX(180deg);
  /* margin:-25px 0 0 100px; */
  transition: 1s linear;
}
.Our-service-dropdown{
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 100px;
  border:1px solid rgb(212, 208, 208);
  border-radius: 10px;
  background:#ffffff;
  margin:0 0 0 790px;
}
.Our-service-dropdown ul{
  list-style-type: none;
}
.Our-service-dropdown ul li{
  margin-bottom: 10px;
  text-align: center;
  margin:0 0 10px -40px;
  transition: 0.5 linear;
  -webkit-transition: 0.5 linear;
  -moz-transition: 0.5 linear;
  -ms-transition: 0.5 linear;
  -o-transition: 0.5 linear;
  cursor: pointer;
}
.Our-service-dropdown ul li:hover{
 background: #bbddec;
 border-bottom:4px solid #14df17;
 transition: 0.5s linear;
 -webkit-transition: 0.5s linear;
 -moz-transition: 0.5s linear;
 -ms-transition: 0.5s linear;
 -o-transition: 0.5s linear;
}
.FirstRow{
  display:flex;
  background: url("/public/images.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height:63vh;
  margin:100px 0 0 0;
}
.FirstRow-left{
  text-align:left;
  padding:40px;
  
}
.FirstRow-left p{
  font-family: "Nunito Sans", sans-serif;
}
.FirstRow-left h3{
  font-size:35px;
  text-align:left;
  color:#14df17;

}
.FirstRow-left button,.Contact-page-left button{
  padding:5px 20px 5px 20px;
  font-size:17px;
  background:rgba(52,94,52,0.46);
  border:none;
  box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -webkit-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -moz-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  font-weight:600;
  color:#fff;
  transition:linear 3s;
  -webkit-transition:linear 3s;
  -moz-transition:linear 3s;
  -ms-transition:linear 3s;
  -o-transition:linear 3s;
}
.Contact-page-left button{
  margin: 10px 0 0 0;
}
.FirstRow-left button::before,.HomeService button::before,.HomeAbout div button::before,.join-right button:before,.VWIB-description button::before,.Contact-page-left button:before{
       content:"";
       width:30px;
       height:30px;
       background:#14df17;
       position:absolute;
       margin:-15px 0 0 -30px;
       border-radius:20px;
       box-shadow: 11px 10px 19px -7px #14df17;
       -webkit-box-shadow: 11px 10px 19px -7px #14df17;
       -moz-box-shadow: 11px 10px 19px -7px #14df17;
       transition: linear 2s;
}
.HomeService button::before{
  z-index:3;
  margin:-15px 0 0 -25px;
}
.HomeAbout div button::before{
  margin:-15px 0 0 -30px;
}
.FirstRow-left button:hover,.HomeAbout div button:hover,.join-right button:hover,.VWIB-description button:HOVER,.Contact-page-left button:hover{
  color:#000;
  font-size: 19px;
  transition: linear 3s;
  -webkit-transition: linear 3s;
  -moz-transition: linear 3s;
  -ms-transition: linear 3s;
  -o-transition: linear 3s;
}
.FirstRow-left button:hover::before,.HomeAbout div button:hover::before,.join-right button:hover:before,.VWIB-description button:hover::before,.Contact-page-left button:hover::before{
  content: "";
  background: #14df17;
  margin:10px 0 0 60px;
  width:32px;
  height: 35px;
  transition: linear 2s;
}
.HomeAbout div button:hover::before{
  margin:10px 0 0 95px;
}
.VWIB-description button:HOVER::before{
  margin:10px 0 0 90px;

}
.VWIB-description button a{
  text-decoration: none;
  color: #ffffff;
  transition: linear 2s;
  -webkit-transition: linear 2s;
  -moz-transition: linear 2s;
  -ms-transition: linear 2s;
  -o-transition: linear 2s;
}
.VWIB-description button a:hover{
  color: #000;
  transition: linear 2s;
  -webkit-transition: linear 2s;
  -moz-transition: linear 2s;
  -ms-transition: linear 2s;
  -o-transition: linear 2s;
}
.Contact-page-left button:hover::before{
  margin:10px 0 0 120px;
}
.FirstRow-Right{
  margin:0 67px 0 0;
}
.FirstRow-Right img{
  height:400px;
  width:750px;
  border-radius: 41% 59% 0% 100% / 100% 0% 100% 0%;
  box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -webkit-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -moz-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  margin:2px 0 0 0;
}
.HomeAbout{
  padding:20px;
  display:flex;
  background-color: rgb(247, 247, 248);
  /* background: url("/public/images.jpg");
  background-size: cover;
  background-repeat: no-repeat; */
}
.HomeAbout div img{
  height:300px;
  width:650px;
  padding:20px;
}
.HomeAbout div p{
  font-size:17px;
  color: rgb(54, 51, 51);
  font-family: "Nunito Sans", sans-serif;
}
.HomeAbout-right{
  padding-left:20px;
}
.HomeAbout-right h2{
  color:#14df17;
}
.HomeAbout div button{
  padding:5px 20px 5px 20px;
  margin-top:20px;
  color:#fff;
  font-weight: 600;
  font-size:17px;
  background:rgba(52,94,52,0.46);
  border:none;
  box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -webkit-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -moz-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  transition:linear 3s;
  -webkit-transition:linear 3s;
  -moz-transition:linear 3s;
  -ms-transition:linear 3s;
  -o-transition:linear 3s;
}
.Home-about h1,.HomeService h1{
  text-decoration:overline;
  text-align: center; 
  color:#14df17;
}
.HomeService{
  padding:20px;
  padding-top: 40px;
  /* background-color: rgb(247, 247, 248); */
}
.HomeService button{
  justify-content: center;
  align-items:center;
  text-align:center;
  color:#fff;
  font-weight: 600;
  transition: linear 3s;
}
.HomeService button:hover{
  color:#000;
  font-size: 19px;
  transition: linear 3s;
}
.HomeService button:hover::before{
  content: "";
  background: #14df17;
  margin:10px 0 0 115px;
  width:32px;
  height: 35px;
  transition: linear 2s;
}
.HomeService-top p{
  text-align: center;
  font-size: 17px;
  color: rgb(54, 51, 51);
  font-family: "Nunito Sans", sans-serif;
}
.HomeService-bottom{
  display: flex;
  padding:20px;
  justify-content: center;
  align-items:center;

}
.Container-1,.Container-2{
  padding:20px;
  width:500px;
  background:rgba(179, 223, 179, 0.46);
  /* border-radius: 54% 46% 0% 100% / 100% 0% 100% 0%; */
  justify-content: center;
  align-items:center;
  text-align: center;
  padding:30px;
  box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -webkit-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -moz-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -webkit-border-radius: 54% 46% 0% 100% / 100% 0% 100% 0%;
  -moz-border-radius: 54% 46% 0% 100% / 100% 0% 100% 0%;
  -ms-border-radius: 54% 46% 0% 100% / 100% 0% 100% 0%;
  -o-border-radius: 54% 46% 0% 100% / 100% 0% 100% 0%;
  border-radius: 48% 52% 9% 91% / 92% 9% 91% 8%;
}
.Container-1 span,.Container-2 span{
  font-size: 50px;
  font-family: "Nunito Sans", sans-serif;
  color:#14df17;
}
.Container-1 h2,.Container-2 h2{
  color:#14df17;
}
.Container-1 button,.Container-2 button{
  padding:5px 20px 5px 20px;
  font-size:17px;
  background:rgba(52,94,52,0.46);
  border:none;
  box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -webkit-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -moz-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
}
.Container-2{
  margin-left:50px;
}
.Container-1::before,.Container-2::before{
  content: "";
  width:100px;
  height:100px;
  position:absolute;
  background-color: #14df17;
  margin:-50px 0 0 150px;
}
.Container-1::after,.Container-2::after{
  content: "";
  width:100px;
  height:100px;
  position:absolute;
  background-color: #bbddec;
  margin:-10px 0 0 -220px;
}
.mission-vision{
  display: flex;
  text-align: center;
  /* background: url("/public/VsBzRe.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  opacity:1;
}
.mission h2,.vision h2{
  text-decoration: overline;
  color:#14df17;
}
.mission p,.vision p{
  backdrop-filter: blur(20px);
  font-size: 19px;
  font-weight: 480;
}
.conj{
  text-align: center;
  background: url("/public/gallery/download.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 80%;
  margin-top:-20px;
  height: 40vh;
  justify-content: center;
  align-items: center;
}
.conj h2,.conj h3{
  /* color: #ffff; */
  backdrop-filter: blur(40px);
  height:35px;
}
.conj img{
  height: 150px;
}
.partners-img{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 100px;
}
.partners-img img{
  margin: 0 0 0 5px;
}
/* .undp img{
  margin: 0 0 0 90px;
} */
.partners h1{
  padding: 20px;
text-align: center;
text-decoration: overline;
color:#14df17;
}
.partners h4{
  text-align: center;
}
.partners-img {
  padding:20px;
  margin-left: 70px;
}
.partners-img div{
  padding-right:90px;
  
}
.partners-img p{
  font-size: 15px;
  color: rgb(54, 51, 51);
  font-weight:400;
  font-family: "Nunito Sans", sans-serif;
  text-align: center;
  margin-left:15px;
}
.partners-img img{
  height:90px;
}
.vcg img{
  margin:0 0 0 35px;
}
.Aw img{
  width: 200px;
}
/* .LatestNews{
  margin-right: 20px;
} */
.LatestNews h1{
  text-align: center;
  color: #14df17;
  text-decoration: overline;
}
.news-flex{
  display: flex;
  max-width: 100%;
  padding: 20px;
  }

.news-box{
  padding: 20px;
  width:300px;
  background:rgba(179, 223, 179, 0.46);
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -webkit-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -moz-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.news-box h4{
  color: #ee0000a1;
  font-size: 17px;
  user-select: none;
}
.news-box p{
  color: #000000b4;
  font-weight: 400;
}
.news-box img{
  height: 200px;
  width:320px;
  margin:-10px 0 0 -10px;
  border-radius: 54% 46% 51% 49% / 72% 48% 52% 28%;
  -webkit-border-radius: 54% 46% 51% 49% / 72% 48% 52% 28%;
  -moz-border-radius: 54% 46% 51% 49% / 72% 48% 52% 28%;
  -ms-border-radius: 54% 46% 51% 49% / 72% 48% 52% 28%;
  -o-border-radius: 54% 46% 51% 49% / 72% 48% 52% 28%;
}
.Join{
  padding: 20px;
  display:flex;
  background-color: rgb(247, 247, 248);
}
.Join img{
  border-radius: 48% 52% 9% 91% / 92% 9% 91% 8%;
  box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
-webkit-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
-moz-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
}
.join-right{
  text-align: center;
  padding-top: 30px;
}
.join-right p{
  font-size: 20px;
  /* color: #fff;
  backdrop-filter: blur(30px); */
}
.join-right h2{
  color:#14df17;
}
.join-right button,.VWIB-description button{
  padding:5px 20px 5px 20px;
  color: #fff;
  font-weight: 600;
  font-size:17px;
  transition: 3s linear;
  background:rgba(52,94,52,0.46);
  border:none;
  box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -webkit-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -moz-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -webkit-transition: 3s linear;
  -moz-transition: 3s linear;
  -ms-transition: 3s linear;
  -o-transition: 3s linear;
}
.Laga{
  width: 650px;
}
.Laga img{
height: 130px;
width:170px;
margin: 20px 0 0 40px;
}
.Laga p{
  text-align: center;
}
.Testimonies-section{
  padding: 20px;
  background-color: rgb(247, 247, 248);
}
.Testimonies-section h1{
  text-align: center;
  text-decoration: overline;
  color:#14df17;
}
.Testimonies{
  display: flex;
}
.Testimonies div{
  padding: 10px;
  border-radius: 10px;
  width: 400px;
  text-align: center;
  margin-left: 20px;
  background:rgba(179, 223, 179, 0.46);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.Testimonies p{
  font-size: 17px;
  color: rgb(54, 51, 51);
  font-weight:400;
  font-family: "Nunito Sans", sans-serif;
}
.Testimonies div h2{
  color:#000;
}
.Testimonies div span{
  color:rgb(196, 49, 49);
}
.scroll-to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 1000;
}

.scroll-to-top-button {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 18px;
  transition: opacity 0.3s, visibility 0.3s;
  opacity: 0.7;
}

.scroll-to-top-button:hover {
  opacity: 1;
}
.Footer{
  display: flex;
}
.Footer div{
  padding-left:30px;
  background: #14df17;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}
.quickLinks{
  padding-bottom: 20px;
}
.quickLinks ul{
  list-style-type: none;
}
.quickLinks ul li{
  padding-bottom: 10px;
  text-decoration: underline;
  color: #551A8B;
  cursor: pointer;
}
.quickLinks ul li:hover,.quickLinks ul li a:hover{
  color: #EE0000;
}
.newsletter ul{
  list-style-type: none;
  display:flex;
}
.newsletter ul li{
  padding-right: 20px;
  font-size: 23px;
  /* color: #fff; */
}
.newsletter input{
  width:200px;
  height: 30px;
  border: none;
  margin-top:-35px;
  outline: none;
}
.newsletter span{
  font-size:20px;
  background-color:#bbddec;
  border-radius: 10px;
padding: 5px;
box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
-webkit-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
-moz-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
}
::placeholder{
  padding: 10px;
}
.Contact-Us ul{
  list-style-type: none;
}
.Footer h4{
  position: absolute;
  margin: 250px 0 0 430px;
}
.Footer h5{
  position: absolute;
  margin: 280px 0 0 490px;
}
@media screen and  (max-width: 475px){
  body,html{
    width: 100%;
    position:relative;
  }
  .Home{
    overflow-x: hidden;
    width: 100%;
    margin:0;
    padding:0;
  }
  .logo img{
  height:40px;
  width: 80px;
  border-radius: 10px;
  margin:0 145px 0 -30px;
}
  .nav-title{
    display: absolute;
  }
  .toggle{
    display: flex;
    margin:-45px 0 0 80%;
  }
  .navbar-ul-mobile{
    margin: 30px 0 0 37%;
    text-align: right;
    font-weight:600;
    color: #EE0000;
    width:170px;
}
.nav-dropdown{
  margin:0 0 0 -20px;
  font-weight:400;
}
.FirstRow-Right img{
  display: none;
}
#FirstRow-1{
  background: url("/public/Annotation 2024-05-25 070012.png");
  background-size: cover;
  width:100%;
  height:600px;
}
#FirstRow-2{
  background: url("/public/WhatsApp Image 2024-06-27 at 20.47.22.jpeg");
  background-size: cover;
  width:100%;
  height:600px;
}
#FirstRow-3{
  background: url("/public/WhatsApp Image 2024-06-27 at 20.47.19 (1).jpeg");
  background-size: cover;
  width:100%;
  height:600px;
}
.FirstRow-left p{
  font-weight: 600;
  color:#bbddec;
  font-size:18px;
}
.HomeService-bottom{
  flex-direction: column-reverse;
}
/* .HomeService-top p{
  text-align: center;ter;
} */
.Container-1,.Container-2{
  border-radius: 0;
  margin:25px;
  width:100%;
}
.Container-1::before,.Container-2::before{
  margin:-70px 0 0 120px;
}
.HomeAbout{
  background: url("/public/gallery/about-section.png");
  width:100%;
  padding:5px;
}
.HomeAbout div img{
  display: none;
}
.mission-vision{
  flex-direction: column-reverse;
}
.partners-img{
  flex-direction: column-reverse;
  margin: 0 0 0 50px;
}
.govt img{
  margin:0 0 0 50px;
}
.vcg img{
  margin:0 0 0 50%;
}
.undp img{
  text-align: center;
  margin:0 0 0 50px;
}

.Join{
  background: url("/public/gallery/h1.png");
  background-size: cover;
}
.Join img{
  display: none;
}
.Testimonies{
  flex-direction: column-reverse;
}
.Testimonies div{
  margin:0 0 10px -5px;
  width:100%;
}
.Footer{
  flex-direction: column-reverse;
}
.SignIn-btn button{
 display: none;
}
.Footer h4{
  /* position: relative; */
  margin: 400px 0 0 0;
  font-size: 16px;
  background: #14df17;
  text-align: center;
}
.Contact-Us ul{
  padding-right:38%;
  text-align: center;
}
.Laga{
  padding-bottom:50px;
}
.Laga img{
  height: 120px;
  width:170px;
padding: 20px 0 0 10%;
}
.Laga p{
  margin-right: 70px;
}
.quickLinks{
  text-align: center;
  margin-left: -70px;
  padding-right:70px ;
}
.quickLinks h3{
  padding-left:10%;
}
.news-box{
  width:100%;
}
.newsletter ul{
  margin-left: 50px;
}
.newsletter h2{
  padding-left: 22%;
}
.newsletter input{
  padding-left: 20%;
}
.newsletter h3{
  padding-left: 25%;
}
.mission-vision div p{
  backdrop-filter: blur(20px);
}
}
@media only screen and (min-width: 660px)and (max-width:1024px){
  body,html{
    width: 100%;
  }
  .logo img{
    height:70px;
  width: 150px;
  margin: 0 500px 0 0;
  }
  .nav{
  display: flex;

  }
  .navbar-ul li{
    display: none;
  }
  .navbar-ul-mobile{
    margin: 30px 0 0 -800px;
    text-align: right;
    font-weight:600;
    color: #EE0000;
    width:170px;
}
.nav-dropdown{
  margin:0 0 0 0;
  font-weight:400;
}
  .toggle{
    display: flex;
  }
  .toggle.active{
    margin: 0 0 0 0;
  }
  .FirstRow-Right img{
    display: none;
  }
  #FirstRow-1{
    background: url("/public/Annotation 2024-05-25 070012.png");
    background-size: cover;
    width:100%;
    height:600px;
  }
  #FirstRow-2{
    background: url("/public/WhatsApp Image 2024-06-27 at 20.47.22.jpeg");
    background-size: cover;
    width:100%;
    height:600px;
  }
  #FirstRow-3{
    background: url("/public/WhatsApp Image 2024-06-27 at 20.47.19 (1).jpeg");
    background-size: cover;
    width:100%;
    height:600px;
  }
  .FirstRow-left p{
    font-weight: 600;
    color:#bbddec;
    font-size:18px;
  }
  .HomeService-bottom{
    flex-direction: column-reverse;
  }
  .Container-1,.Container-2{
    border-radius: 0;
    margin:25px;
    width:100%;
  }
  .Container-1::before,.Container-2::before{
    margin:-70px 0 0 120px;
  }
  .HomeAbout{
    background: url("/public/gallery/about-section.png");
    width:100%;
  }
  .HomeAbout div img{
    display: none;
  }
  .Join{
    background: url("/public/gallery/h1.png");
    background-size: cover;
  }
  .join-right p{
    font-size: 20px;
    color: #fff;
    backdrop-filter: blur(30px);
  }
  .Join img{
    display: none;
  }
  .conj {
    height:100%;
  }
  .Testimonies{
    flex-direction: column-reverse;
  }
  .Testimonies div{
    margin:0 0 10px -5px;
    width:100%;
  }
  .partners{
    padding: 30px 0 0 0;
  }
  .partners-img{
    flex-direction: none;
    margin:0 0 0 10%;
  }
  .partners-img div p{
    display: none;
  }
  .partners-img div img{
    width:70px;
    height:60px;
    margin-left: -10px;
    /* padding:7px; */
  }
  .govt img{
    /* margin:0 0 0 50px; */
  }
  .vcg img{
    /* margin:0 0 0 45%; */
  }
  .undp img{
    text-align: center;
    /* margin:0 0 0 80px; */
  }
  .Footer{
    flex-direction: column-reverse;
  }
  .SignIn-btn button{
    display: none;
  }
  .Footer h4{
    /* position: relative; */
    margin: 400px 0 0 200px;
    font-size: 16px;
    background: #14df17;
    text-align: center;
  }
  .Laga{
    padding-bottom:70px;
  }
  .Laga img{
    justify-content: center;
    align-items: center;
    padding-left: 31%;
  }
  .Laga p{
    margin-right: 70px;
  }
  .quickLinks{
    text-align: center;
    margin-left: -40px;
    padding-right:70px ;
  }
  .quickLinks ul{
    margin-left: -50px; 
  }
  .news-box{
    width:100%;
  }
  .newsletter{
    text-align: center;
  }
  .Contact-Us ul{
    text-align: center;
    margin-left: -120px;
  }
  .newsletter ul{
    flex-direction: row;
text-align: center;  
margin-left: 37%;  
  }
  .newsletter h2{
    margin-left: -50px;
  }
  .newsletter h3{
    margin-left: -70px;
  }
  .Footer h4{
    margin-left: 40;
  }
  
}
@media screen and (min-width: 1028px) and (max-width:1280px){
  .FirstRow{
    height: 75vh;
  }
  .navbar-ul{
    margin: -80px 0 0 40%;
  }
  .SignIn-btn button{
    /* display: none; */
  }
}
@media screen and (max-width: 270px){
  body{
    display: none;
  }
}



/* about us page */
.About-header{
  text-align: center;
  background: url("/public/gallery/network.jpeg");
  background-size: cover;
  color: #fff;
  padding: 130px;
  box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
-webkit-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
-moz-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
}
.About-nav{
  position: fixed;
  margin:0 0 100px 0;
  background: #fff;
  width:100%;
z-index:10;
}
.About-header h1{
  font-size: 50px;
  font-weight: 800;
  color: #14df17;
  text-decoration: overline;
}
.About-header p{
  font-size: 21px;
  font-weight: 600;
}
.who-we-are{
  background-color: rgb(247, 247, 248);
  text-align: center;
  padding:30px 200px 30px 200px;
}
.who-we-are h1{
  color: #14df17;
}
.who-we-are p{
  color: rgb(54, 51, 51); 
}
/* .who-we-are h3{
  color: rgb(29, 27, 27); 
} */
.who-we-do{
  display: flex;
  padding:20px 200px 0 200px;
  background: url("/public/images.jpg");
  background-size: cover;
}
.who-we-do h1{
  color: #14df17;
}
.who-we-do p{
  color: rgb(54, 51, 51); 
}
.who-we-do-description{
  width: 500px;
  text-align: left;
  padding-right: 30px;
  /* background-color: rgb(247, 247, 248);
  border-radius: 20px;
  padding: 10px; */

}
.who-we-do div img{
  height:300px;
  width:450px;
  box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
-webkit-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
-moz-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  /* border-radius: 20px; */
}

.wishes{
  background-color: rgb(247, 247, 248);
  height: 70vh;
  display: flex;
}
.wishes div img{
  height:400px;
  width:600px;
}
.wishes div h1{
  color: #14df17;
}
.wishes div h4{
  color: #14df17;
}
.wishes div p{
  color: rgb(54, 51, 51); 
}
.wishes div{
  width: 700px;
  margin: 40px 0 0 0;
}
.directors table {
  text-align:left;
  margin: 0 0 0 35px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  font-weight: 700;
}
.directors table tr td,.directors table tr th{
  padding-left: 20px;
  font-family: "Nunito Sans", sans-serif;
}
.VWIB{
  padding: 40px 100px 20px 100px ;
  display: flex;
  background: url("/public/VsBzRe (1).jpg");
  background-size: cover;
}
.VWIB div img{
  box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -webkit-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -moz-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
}
.VWIB-description{
  width: 600px;
  padding: 0 20px 0 0;
  text-align: left;
  color: rgb(54, 51, 51); 
}
.VWIB-description h1{
  color: #14df17;
}

@media only screen and (max-width: 475px){
  .About-header h1{
    font-size: 30px;
    text-wrap: nowrap;
  }
  .About-header p{
    text-align: center;
    width: 250px;
    margin-left: -80px;
    font-weight: 600;
    color:#bbddec;
    backdrop-filter: blur(20px);
    padding: 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
  .who-we-are{
    padding:10px;
  }
    .who-we-do{
      width: 80%;
      padding: 10px;
      flex-direction: column-reverse;
      margin:20px;
    }
    .who-we-do h1,.who-we-do p{
text-align: center;
width: 270px;
    }
  .who-we-do div img{
    width: 100%;
    margin: 0;
  }
  .wishes div img{
    display: none;
  }
  .wishes{
    padding:10px 5px 10px 5px;
    width: 100%;
    height:630px;
  }
  .wishes div h1{
    padding: 20px;
    font-size: 23px;
  }
  .wishes div h3{
    margin: 0 340px 0 0;
    font-size: 18px;
    text-align: center;
    padding:5px;
  }
   .wishes div p{
     margin: 10px 340px 0 0;
     padding:5px;
    text-align: center;
    
   }
   .directors table{
      width: 350px;
      margin:0 150px 0 0;
   }
   .directors h4{
    text-align: center;
    margin:0 340px 20px 0;
   }
   .VWIB{
     width: 80%;
      padding: 20px;
      flex-direction: column-reverse;
      margin:20px;
      background: url("/public/images.jpg");
      background-size:cover;
   }
   .VWIB div img{
    width: 100%;
   }
   .VWIB-description{
    width: 100%;
   }
}
@media only screen and (min-width: 667px)and (max-width:1024px){
  .About-header h1{
    font-size: 30px;
    text-wrap: nowrap;
  }
  .About-header p{
    text-align: center;
    width: 250px;
    margin-left: 30%;
    font-weight: 600;
    color:#bbddec;
    backdrop-filter: blur(20px);
    padding: 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
  .who-we-are{
    padding:10px;
  }
    .who-we-do{
      width: 80%;
      padding: 7px;
      flex-direction: column-reverse;
      margin:10%;
    }
    .who-we-do h1,.who-we-do p{
text-align: center;
width: 500px;
padding-left: 10px;
    }
  .who-we-do div img{
    width: 100%;
    margin: 0;
  }
  .wishes div img{
    display: none;
  }
  .wishes{
    padding:10px 5px 10px 15px;
    width: 100%;
    height:630px;
  }
  .wishes div h1{
    text-align: center;
    margin-right: 100px;
    /* font-size: 23px; */
  }
  .wishes div h3{
    margin: 0 100px 0 0;
    /* font-size: 18px; */
    text-align: center;
    padding:20px;

  }
   .wishes div p{
     /* margin: 10px 340px 0 0; */
     padding:5px 100px 0 0;
    text-align: center;
    
   }
   .directors table{
      /* width: 500px; */
      margin:0 50px 0 0;
   }
   .directors h4{
    text-align: center;
    /* margin:0 340px 20px 0; */
   }
   .VWIB{
     width: 80%;
      padding: 50px;
      flex-direction: column-reverse;
      margin:20px;
      background: url("/public/images.jpg");
      background-size:cover;
   }
   .VWIB div img{
    width: 100%;
   }
   .VWIB-description{
    width: 100%;
   }
}

/* contact us */
.Contact-page{
  padding: 130px 0 0 120px;
  display: flex;
  background: url("/public/images.jpg");
  background-size: cover;

}
.contact-nav{
  position:fixed;
  background-color: #ffffff;
  width: 100%;
  z-index:10;
}
.Fullnames{
  display: flex;
}
.Fullnames div{
  padding: 0 30px 0 0;
}
.Fullnames div input{
  width: 180px;
  height: 30px;
  border-radius: 5px;
 border: none;
  outline: none;
}
.Email input,.Phone input,.City input{
  width: 395px;
  height: 30px;
  border-radius: 5px;
  outline: none;
  border: none;
}
.Email,.Phone,.City,.Fullnames{
  padding:10px 0 10px 0;
}
.Email label,.Phone label,.City label,.Fullnames label{
  font-weight: 500;
}
.Contact-page-left{
  background: rgb(179, 223, 179, 0.46);
  width: 450px;
  padding: 30px 30px 100px 50px;
  margin:0 50px 0 0;
  border-radius:10px ;
  -webkit-border-radius:10px ;
  -moz-border-radius:10px ;
  -ms-border-radius:10px ;
  -o-border-radius:10px ;
}
.Contact-page-left h3,.Contact-page-left h4{
  color: #14df17;
}
.radios{
  display: flex;
}
.radios p{
  margin:0 100px 0 0;
  font-weight: 500;
}
.textArea textarea{
  width: 390px;
  height: 100px;
  border: none;
  outline: none;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.contact-details{
  background: rgb(179, 223, 179, 0.46);
  padding: 20px;
  margin:0 0 40px 0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -webkit-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -moz-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
}
.contact-details::before{
  content: "";
  width:100px;
  height:100px;
  position:absolute;
  background-color: #14df17;
  margin:-50px 0 0 400px;
}
.contact-details::after{
  content: "";
  width:100px;
  height:100px;
  position:absolute;
  background-color: #bbddec;
  margin:-20px 0 0 0;
}
.contact-details div p{
  font-size: 18px;
  font-weight: 400;
}
.contact-details h2 p{
  color: #14df17;
}
.contact-details h2 span{
  color: #14df17;
}
.contact-details h2 {
  /* color: #14df17; */
}
.contact-footer{
  margin: 5px 0 0 0;
}
@media only screen and (max-width: 475px){
  .Contact-page{
    flex-direction: column;
    padding: 130px 0 0 0;
  }
  .Contact-page-left{
    width: 100%;
    padding:30px;
    margin-bottom:50px;
  }
  .Contact-page-left div h4{
    width:80%;
  }
  .Fullnames div input{
    width: 70%;
  }
  .Email input,.Phone input,.City input{
    width: 80%;
  }
  .textArea textarea{
    width: 80%;
  }
  .textArea h3{
    width:80%;
  }
  .contact-details::before{
    margin:-90px 0 0 260px;
  }
  .map div iframe{
    width: 400px;
  }
}
@media only screen and (min-width: 667px)and (max-width:1024px){
  .Contact-page{
    flex-direction: column;
    padding: 130px 0 0 0;
  }
  .Contact-page-left{
    width: 100%;
    padding:30px;
    margin-bottom:50px;
  }
  .Contact-page-left div h4{
    width:90%;
  }
  .Fullnames div input{
    width: 100%;
  }
  .Email input,.Phone input,.City input{
    width: 90%;
  }
  .textArea textarea{
    width: 90%;
  }
  .textArea h3{
    width:90%;
  }
  .contact-details::before{
    margin:-90px 0 0 560px;
  }
  .map div iframe{
    width: 1024px;
  }
}
/* service page */
.Service-Top{
  display: flex;
  padding: 10px 150px 20px 100px;
  background: url("/public/images.jpg");
  background-size: cover;
}
/* .servie-nav{
  position: fixed;
  background: #ffffff;
  width: 100%;
} */
.Service-Top div img{
  width: 500px;
  height:400px;
  border-radius: 48% 52% 17% 83% / 73% 13% 87% 27%;
  box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -webkit-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -moz-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
}
.Service-descripton{
  padding: 50px 0 0 0;
  width: 600px;
}
.Service-descripton h1,.mentorship div h1,.workshops div h1,.sensitization div h1,.Service-last div h1{
  color: #14df17;
  /* text-decoration: overline; */
}
.Service-descripton p,.mentorship div p,.workshops div p,.sensitization div ul li,.Service-last div ul li{
  font-family: "Nunito Sans", sans-serif;
  color: #4e4646;
  font-size: 18px;
}
.mentorship{
  display: flex;
  padding: 40px;
  background: rgb(179, 223, 179, 0.46);
  margin: 15px 50px 25px 50px;
  border-radius: 10px;
}
.mentorship div img,.sensitization div img{
  width: 600px;
  height: 300px;
  padding: 0 0 0 20px;
  border-radius: 10px;
}
.workshops,.Service-last{
  display: flex;
  padding:20px ;
  background: rgba(230, 238, 230, 0.46);
  margin: 15px 0 25px 0;
}
.workshops div img,.Service-last div img{
  width: 600px;
  height: 350px;
  padding: 0 40px 0 0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.sensitization{
  display: flex;
  padding: 40px;
  background: rgb(179, 223, 179, 0.46);
  margin: 15px 50px 25px 50px;
  border-radius: 10px;
}
.sensitization div{
  text-align: left;
}
@media only screen and (max-width: 475px){
  .Service-Top{
    background: url("/public/gallery/gallery4.png");
    width:100%;
  }
  .Service-Top div h1{
    text-align: center;
    margin: 0 0 20px -450px;
  }
  .Service-Top div p{
    width: 300px;
    margin: 0 0 0 -13%;
    background: #3f3b3b;
    backdrop-filter: blur(20px);
    color: #bbddec;
    padding:10px;
    box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.44) inset;
    -webkit-box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.44) inset;
    -moz-box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.44) inset;
}

  .Service-Top div img{
    display: none;
  }
  .mentorship,.sensitization{
    flex-direction: column-reverse;
    width: 330px;
    padding:5px;
    margin:15px 0 0 13px;
  }
  .mentorship div img,.sensitization div img{
    width: 320px;
    height:150px;
    padding-top: 15px;
    border-radius: 7px;
    margin-left: -15px;

}
  .workshops div img,.Service-last div img{
    width:100%;
    height:150px;
  }
  .Service-descripton h1,.mentorship div h1,.workshops div h1,.sensitization div h1,.Service-last div h1{
    text-align: center;
    font-size:28px;
  }
  .workshops div h1{
    text-wrap: nowrap;
    font-size: 26px;
    margin-left: -20px;
  }
  .Service-descripton p,.mentorship div p,.workshops div p{
    text-align: left;
    padding: 10px 10px 10px 20px;
  }
  .sensitization div ul li,.Service-last div ul li{
    text-align: left;
  }
  .workshops{
    flex-direction: column-reverse;
  }
  .Service-last{
    flex-direction: column-reverse;
  }
}
@media only screen and (min-width: 667px)and (max-width:1024px){
  .Service-Top{
    background: url("/public/gallery/gallery4.png");
    width:100%;
  }
  .Service-Top div h1{
    text-align: center;
    /* margin: 0 0 20px -200px; */
  }
  .Service-Top div p{
    width: 500px;
    /* margin: 0 0 0 -78px; */
    background: #4e4646;
    backdrop-filter: blur(20px);
    color: #bbddec;
    padding:10px 50px 10px 50px;
  }

  .Service-Top div img{
    display: none;
  }
  .mentorship,.sensitization{
    flex-direction: column-reverse;
    /* width: 330px; */
    padding:15px;
    width:80%;
    margin:15px 0 0 50px;
  }
  .mentorship div img,.sensitization div img{
    width: 100%;
    padding-top: 15px;
    border-radius: 7px;
    margin-left: -20px;

}
  .workshops div img,.Service-last div img{
    width:100%;
  }
  .Service-descripton h1,.mentorship div h1,.workshops div h1,.sensitization div h1,.Service-last div h1{
    text-align: center;
    font-size:28px;
  }
  .workshops div h1{
    text-wrap: nowrap;
    font-size: 26px;
    margin-left: -20px;
  }
  .Service-descripton p,.mentorship div p,.workshops div p{
    text-align: center;
    padding: 10px;
  }
  .sensitization div ul li,.Service-last div ul li{
    text-align: left;
  }
  .workshops{
    flex-direction: column-reverse;
  }
  .Service-last{
    flex-direction: column-reverse;
  }
}
/* Gallery-page */
.Gallery-header{
  text-align: center;
  padding: 10px 150px 20px 150px;
  margin:30px 100px 0 100px;
  border-radius: 10px;
  background: rgb(179, 223, 179, 0.46);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.Gallery-header::before{
  content: "";
  width:100px;
  height:100px;
  position:absolute;
  background-color: #14df17;
  margin:-50px 0 0 420px;
}
.Gallery-header::after{
  content: "";
  width:100px;
  height:100px;
  position:absolute;
  background-color: #bbddec;
  margin:-20px 0 0 -520px;
}
.Gallery-header h1{
  color: #14df17;
}
.Gallery-header p{
  font-size: 17px;
}
.gallery-body{
  display: flex;
  flex-wrap: wrap;
  padding:100px;
  width: 1400px;
}
.gallery-Image {
  padding:0 20px 20px 20px;
}
.gallery-Image img{
  height: 200px;
  width:250px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
@media only screen and (max-width: 475px){
  .Gallery-header{
    margin: 50px 0 0 0;
    padding:20px;
  }
  .Gallery-header::before{
    margin:-80px 0 0 70px;
  }
  .Gallery-header::after{
    margin:-10px 0 0 -170px;
  }
  .gallery-body{
    display: flex;
    flex-wrap: wrap;
    padding:80px 20px 20px 5px;
    width: 400px;
  }
  .gallery-Image img{
    height:120px;
    width: 150px ;
  }
}
@media only screen and (min-width: 667px)and (max-width:1024px){
  .Gallery-header{
    margin: 50px 0 0 0;
    padding:20px;
  }
  .Gallery-header::before{
    margin:-80px 0 0 200px;
  }
  .Gallery-header::after{
    margin:-10px 0 0 -300px;
  }
  .gallery-body{
    display: flex;
    flex-wrap: wrap;
    padding:80px 20px 20px 40px;
    width: 100%;
  }
  .gallery-Image img{
    height:120px;
    width: 150px ;
  }
}
.SignIn-btn{
  position: absolute;
  margin:200px 0 0 740px;
}
.SignIn-btn button{
  justify-content: center;
  align-items:center;
  text-align:center;
  color:#fff;
  font-weight: 600;
  transition: linear 1s;
  padding:5px 30px 5px 30px;
  background: #EE0000;
  border: none;
  -webkit-transition: linear 1s;
  -moz-transition: linear 1s;
  -ms-transition: linear 1s;
  -o-transition: linear 1s;
}
.SignIn-btn button:hover{
  color:#fff;
  font-size: 15px;
  transition: linear 1s;
  background: black;
  -webkit-transition: linear 1s;
  -moz-transition: linear 1s;
  -ms-transition: linear 1s;
  -o-transition: linear 1s;
}
.Auth-Box{
  padding: 20px;
  background: #bbddec;
  border-radius: 5px;
  margin:150px 0 0 500px;
  width: 350px;
  box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -webkit-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -moz-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
}
.Auth-Box h3{
  color: #EE0000;
  text-align: center;
}

.Sign-In{
  text-align: left;
}
.Sign-In h1{
  color: #14df17;
  text-align: center;
}
.Sign-In div label{
  font-weight: 450;
}
.Sign-In div input{
  width: 350px;
  height: 30px;
  border: none;
  outline: none;
  border-radius: 5px;
  margin: 10px 0 10px 0;
}
.Sign-In div p{
  margin:15px 0 0 10px;
}
.password-auth{
  display: flex;
}
.Sign-In div alert p{
color: #EE0000;
font-weight: 700;
text-align: center;
}
.Sign-In button,.admin-profile button{
  justify-content: center;
  align-items:center;
  text-align:center;
  color:#fff;
  font-weight: 600;
  transition: linear 1s;
  padding:5px 50px 5px 50px;
  background: #EE0000;
  border: none;
  margin:20px 0 0 100px;
  box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -webkit-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -moz-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);

}
.Sign-In button:hover,.admin-profile button:hover{
  color:#fff;
  font-size: 15px;
  transition: linear 1s;
  background: black;
  -webkit-transition: linear 1s;
  -moz-transition: linear 1s;
  -ms-transition: linear 1s;
  -o-transition: linear 1s;
}
.admin-profile{
  margin: 210px 0 0 0;
}
.admin-profile button{
  margin:0 0 0 0;
}
.admin-profile img{
  height: 50px;
  width: 50px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.Admin-page{
  padding: 50px;
  display: flex;
}
.Admin-page div ul{
  list-style-type: none;
}
.Admin-page div ul li{
  margin: 10px 0 10px -35px;
  cursor: pointer;
}
.Admin-page div ul li:hover,.Admin-page div ul li a:hover{
  background: #14df17;
  width: 200px;
  border-bottom: 3px solid #EE0000;
  color: #fff;
  font-weight: 600;
  transition: linear 0.5s;
}
.Admin-page div ul li a,.Admin-page div ul li{
  text-decoration: none;
  font-size: 20px;
  transition: linear 0.5s;
  width: 200px;
}
.admin-profile p{
font-size: 18px;
}
.Admin-ul img{
  height: 100px;
  width: 170px;
  margin: -30px 0 0 0;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.Line{
  width: 5px;
  height: 120vh;
  background:#bbddec;
  margin: -40px 0 0 0;
  box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -webkit-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -moz-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
}
.Admin-members-records{
  background: rgba(179, 223, 179, 0.46);
  display: flex;
  padding: 20px 30px 20px 30px;
  box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.44) inset;
  -webkit-box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.44) inset;
  -moz-box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.44) inset;
}
.Admin-members-records::before{
  content: "";
  width:100px;
  height:100px;
  position:absolute;
  background-color: #14df17;
  margin:-100px 0 0 270px;
}
.Admin-members-records::after{
  content: "";
  width:100px;
  height:100px;
  position:absolute;
  background:#bbddec;
  margin:110px 0 0 0;
}
.Admin-members-records div{
  padding: 20px 60px 20px 60px;
}
.Admin-members-records div label,.Admin-members-records div p{
  font-weight: 600;
  text-align: center;
  font-size: 17px;
}
.gallery-admin{
  margin: 100px 0 0 0;
}
.gallery-admin-flex{
  display: flex;
  background: rgba(179, 223, 179, 0.46);
  padding: 5px 30px 5px 30px;
  margin-top:10px;
  border-radius: 5px;
  box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.44) inset;
  -webkit-box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.44) inset;
  -moz-box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.44) inset;

}
.display-Image-admin{
  height: 40vh;
  overflow-y:auto;
}
.gallery-admin-flex button,.gallery-admin button{
  margin: 0 0 10px 30px;
  justify-content: center;
  align-items:center;
  text-align:center;
  color:#fff;
  font-weight: 600;
  transition: linear 0.5s;
  padding:5px 50px 5px 50px;
  background: #EE0000;
  border: none;
  box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -webkit-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -moz-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -webkit-transition: linear 0.5s;
  -moz-transition: linear 0.5s;
  -ms-transition: linear 0.5s;
  -o-transition: linear 0.5s;
}
.gallery-admin-flex button:hover,.gallery-admin button:hover{
  color:#fff;
  font-size: 15px;
  transition: linear 0.5s;
  background: black;
  -webkit-transition: linear 0.5s;
  -moz-transition: linear 0.5s;
  -ms-transition: linear 0.5s;
  -o-transition: linear 0.5s;
}
.gallery-admin div img{
  height: 100px;
  width: 100px;

}
.Right-admin{
  margin: -20px 0 0 80px;
  background: url("/public/images.jpg");
  background-size: cover;
  border-radius: 5px;
  width: 500px;
}
.Admin-LatestNews{
  background: #14df17;
  width: 500PX;
  margin: 30px 0 0 30px;
  border-radius: 5px;
  overflow-y:auto;
  height: 100vh;
  box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.44) inset;
  -webkit-box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.44) inset;
  -moz-box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.44) inset;
}
.Admin-LatestNews h2{
  text-align: center;
}
.Admin-LatestNews div{
  background: #dfe7eb;
  padding-top: 10px;
}
.Admin-LatestNews div img{
  width:100%;
  height: 200px;
}
.Admin-LatestNews div p{
  padding: 30px 30px 30px 30px;
}
.LatestNews-btn{
  /* margin-bottom: 30px; */
}
.LatestNews-btn button{
 margin:0 0 0 30px;
}
.Post-News{
  margin: 20px 20px 20px 20px;
  border-radius: 5px;
  padding:10px;
}
.Admin-LatestNews div textarea{
  width: 340px;
  height: 70px;
  outline: none;
  border: none;
  margin: 10px 0 10px 0;
  border-radius: 5px;
}
.Admin-LatestNews div button,.Add-image button,.confirmation-delete button,.news-delete button,.add-account-box form div button{
  margin:-150px 0 0 30px;
  justify-content: center;
  align-items:center;
  text-align:center;
  color:#fff;
  font-weight: 600;
  transition: linear 0.5s;
  padding:5px 50px 5px 50px;
  background: #EE0000;
  border: none;
  margin:20px 0 0 30px;
  box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -webkit-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -moz-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);}
.Admin-LatestNews div button:hover,.Add-image button:hover,.confirmation-delete button:hover,.news-delete button:hover,.add-account-box form div button:hover{
  color:#fff;
  font-size: 15px;
  transition: linear 0.5s;
  background: black;
  -webkit-transition: linear 0.5s;
  -moz-transition: linear 0.5s;
  -ms-transition: linear 0.5s;
  -o-transition: linear 0.5s;
}
.Add-image button{
  margin: 30px 0 0 15px;
}
.Add-image{
  position: absolute;
  backdrop-filter: blur(70px);
  padding:60px 81px 60px 81px;
  border-radius: 10px;
  margin: 210px 0 0 290px;
  box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.44) inset;
  -webkit-box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.44) inset;
  -moz-box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.44) inset;
}
.Add-image h3{
  text-align: center;
}
.Add-image input{
  height:20px;
  width: 300px;
  background: #c0bebe;
}
.preview,.preview img{
  width: 170px;
  height: 130px;
  margin:10px 0 0 0;
  background: #c0bebe;
}
.preview img{
  padding-top:0;
}
.preview p{
  text-align: center;
  font-weight: 700;
  padding: 50px;
}
.confirmation-delete,.news-delete{
  position: absolute;
  background: #bbddec;
  padding:60px;
  border-radius: 10px;
  margin: 290px 0 0 300px;
  box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -webkit-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -moz-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
}
.confirmation-delete p{
  font-size: 17px;
}
.confirmation-delete button{
  margin:30px 0 0 20px;
}
.news-delete{
  margin:0 0 0 800px;
}
.news-delete button{
  margin: 0 0 0 10px;
}
/* accounts page */
.accounts-page{
  display: flex;
  padding:60px 0 0 100px;
  background: url("/public/istockphoto-1367756024-612x612.jpg");
  background-size: cover;
  height: 90vh;
}
.add-account{
  padding:30px;
  background: #bbddec;
  width: 370px;
  height: 80vh;
  border-radius: 5px;
  margin:0 60px 0 0;
  box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.44) inset;
  -webkit-box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.44) inset;
  -moz-box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.44) inset;
}
.add-account h2{
text-align: center;
color: #14df17;
}
.add-account-box form div{
  margin:20px 0 0 0;
}
.add-account-box form div input{
  width: 350px;
  height: 35px;
  outline:none;
  border: none;
  margin:3px 0 0 0;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.add-account-box form div button{
  margin-left: 90px;
}
.add-account-box form div alert p{
  color: #EE0000;
  font-weight: 700;
  text-align: center;
}

/* all admins */
.allAdmins {
  width: 700px;
}
.allAdmins div h1,.allAdmins div p{
  text-align: center;
  color:#14df17;
}
.allAdmins-table table tr{
  margin:10px 0 0 0;
}

.allAdmins-table table tr th,.allAdmins-table table tr td{
  padding:5px 50px 5px 50px;
  box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.44) inset;
  -webkit-box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.44) inset;
  -moz-box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.44) inset;
}
.allAdmins-table table tr th{
  font-size: 18px;
  color:#EE0000;
}
.allAdmins-table table tr td{
  background: #bbddec;
  border-radius: 10px;
}
.admin-bottom{
  background: #14df17;
  height: 70px;
  margin-top: -10px;
  box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.44) inset;
  -webkit-box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.44) inset;
  -moz-box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.44) inset;
}
.Success-verification,.unsuccesful{
  background: #bbddec;
  padding: 10px;
  text-align: center;
  width: 100%;
  font-weight: 500;
  color: #EE0000;
}
.verify-loading p{
  margin: 0 0 0 600px;
}
.register-members{
  padding: 30px;
  background: #bbddec;
  width: 360px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin:30px 0 0 30px;
}
.register-members form h2{
  text-align: center;
  color: #14df17;
}
.register-members div input{
  width: 350px;
  height: 30px;
  margin:5px 0 15px 0;
  outline:none;
  border: none;
  border-radius: 5px;
}
.membership-type{
  display: flex;
  margin: 0 0 20px 0;
}
.membership-type p input{
  width: 15px;
  margin:10px 10px 0 0;
}
.membership-type p{
  margin: 0 100px 0 60px;
}
.register-members div button{
  padding: 5px 50px 5px 50px;
  border: none;
  background: #EE0000;
  color:#fff;
  font-weight: 700;
  font-size: 15px;
  transition: linear 0.5s;
  margin:20px 0 0 100px;
  box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -webkit-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
  -moz-box-shadow: 11px 10px 19px -7px rgba(52,94,52,0.46);
}
.register-members div button:hover{
  color:#fff;
  font-size: 17px;
  transition: linear 0.5s;
  background: black;
  -webkit-transition: linear 0.5s;
  -moz-transition: linear 0.5s;
  -ms-transition: linear 0.5s;
  -o-transition: linear 0.5s;

}
.members-page{
  display: flex;
}

.members-right{
  margin: 0 0 0 30px;
  background: url("/public/images.jpg");
  background-size: cover;
  width: 100%;
  height:110vh;
  overflow-y:auto;
}
.members-right div h2{
  color: #14df17;
  text-align: center;
}
.members-right table tr td{
  padding:5px 20px 5px 20px;
  box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.44) inset;
  -webkit-box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.44) inset;
  -moz-box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.44) inset;
}
.member-error alert p{
  color: #EE0000;
  font-weight: 700;
  text-align: center;
}